.file-links {
  text-align: left;
  ul {
    padding: 0;
  }
  li {
    list-style: none;

    a {
      display: inline-block;
      margin: 0.1rem;
      padding: 0.2rem 0.5rem 0.2rem 1.4rem;
      position: relative;
      &:before {
        content: url("/assets/icons/file-pdf-regular.svg");
        background-size: 1rem 1rem;
        height: 1rem;
        width: 1rem;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0.2rem;
        color: #999;
      }

      &:after {
        content: '';
        position: absolute;
        height: 2px;
        left: 1.4rem;
        bottom: 0;
        max-width: 0;
        display: block;
        width: calc(100% - 1.4rem);
        transition: all 0.3s;
        background-color: $primary-color;
      }

      &:hover, &:focus {
        text-decoration: none;

        &:after {
          max-width: 100%;
        }
      }
    }
  }
}