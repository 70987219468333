.formStep {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}
.formStep__step {
  list-style: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  &--active {
    font-weight: bold;
    border-bottom: solid 3px $color-gold;
  }
}
