%bannerSize--thin {
  height: 180px;

  @include media-breakpoint-up(sm) { // 576px and up
    height: 190px;
  }
  @include media-breakpoint-up(md) { // 768px and
    height: 200px;
  }
  @include media-breakpoint-up(lg) { // 992px and up
    height: 220px;
  }
  @include media-breakpoint-up(xl) { // 1200px and up
    height: 250px;
  }
  @media (min-width: 1601px) {
    height: 270px;
  }
  @media (min-width: 1901px) {
    height: 290px;
  }
  @media (min-width: 2201px) {
    height: 300px;
  }
}