b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: $weight-normal;
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}
h4 {
  letter-spacing: 1px;
}

// font-family
h1, h2 {
  font-family: $family-header;
}
h3 {
  font-family: $family-header-h3;
  font-weight: 400;
  font-style: normal; 
}
h4, h5, h6 {
  font-family: $family-base;
}

// size
h1 {
  font-size: rem(36, $font-size-mobile);
}
h2 {
  font-size: rem(28, $font-size-mobile);
}
h3 {
  font-size: rem(24, $font-size-mobile);
}
h4 {
  font-size: rem(22, $font-size-mobile);
}
h5 {
  font-size: rem(14, $font-size-mobile);
}
@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(64, $font-size-desktop);
  }
  h2 {
    font-size: rem(56, $font-size-desktop);
  }
  h3 {
    font-size: rem(48, $font-size-desktop);
  }
  h4 {
    font-size: rem(36, $font-size-desktop);
  }
  h5 {
    font-size: rem(16, $font-size-desktop);
  }
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}
.text-big {
  font-size: 1.5rem;
}