.col-md-2.favorite_text {
  padding-left: 0;
  padding-right: 0;
  @media screen and (max-width: 400px) {
    padding-right: 1rem;
  }
}
.col-md-2.favorite_text:hover {
  color: red;
}
.col-md-2.favorite_text svg {
  margin-left: 0.5rem;
}
.product__banner {
  @media screen and (max-width: 400px) {
    background-size: 390px 310px;
  }
}
