.htmlPanel {
  max-width: 960px;
  margin: auto;

  span.special-font {
    font-family: $family-header;
    //font-size: 0.72em;
  }

  span.aos_effect_up {
    display: inline-block;
  }
}

div.imagePanel {
  position: relative;
  a.button {
    color: white;
    background: $color-green;
    padding: 10px 35px;
    font-weight: $weight-bold;
    letter-spacing: 2px;
    box-shadow: 0.5px 1px 2px 0 rgba(0, 0, 0, 0.25);
    &.secondary {
      background-color: transparent;
      border: 2.77px solid #daa521;
      color: #daa521;
      &:focus,
      &:hover {
        background: #daa521;
        border: 2.77px solid #daa521;
        color: $color-light-red;
        text-decoration: none;
      }
    }
    &:focus,
    &:hover {
      background: $color-dark-green;
      text-decoration: none;
    }
  }
}

div.imagePanel .htmlPanel {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  img {
    max-width: 32%;
    @media (min-width: 456px) {
      max-width: 26%;
    }
    @include media-breakpoint-up(sm) {
      // 576px and up
      max-width: 18%;
    }
    @include media-breakpoint-up(md) {
      // 768px and
      max-width: 17%;
    }
    @include media-breakpoint-up(lg) {
      // 992px and up
      max-width: 20%;
    }
    // @include media-breakpoint-up(xl) { // 1200px and up
    //   max-width: 20%;
    // }
    @media (min-width: 1601px) {
      max-width: 100%;
    }
  }
}

div.imagePanel.big__text {
  .htmlPanel.big__text {
    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

div.youtubePanel .htmlPanel {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  z-index: 4;
  img {
    max-width: 32%;
    @media (min-width: 456px) {
      max-width: 26%;
    }
    @include media-breakpoint-up(sm) {
      // 576px and up
      max-width: 18%;
    }
    @include media-breakpoint-up(md) {
      // 768px and
      max-width: 17%;
    }
    @include media-breakpoint-up(lg) {
      // 992px and up
      max-width: 20%;
    }
    // @include media-breakpoint-up(xl) { // 1200px and up
    //   max-width: 20%;
    // }
    @media (min-width: 1601px) {
      max-width: 100%;
    }
  }
}

div.youtubePanel {
  position: relative;
  a.button {
    color: white;
    background: $color-green;
    padding: 10px 35px;
    font-weight: $weight-bold;
    letter-spacing: 2px;
    box-shadow: 0.5px 1px 2px 0 rgba(0, 0, 0, 0.25);
    &.secondary {
      background-color: transparent;
      border: 2.77px solid #daa521;
      color: #daa521;
      &:focus,
      &:hover {
        background: #daa521;
        border: 2.77px solid #daa521;
        color: $color-light-red;
        text-decoration: none;
      }
    }
    &:focus,
    &:hover {
      background: $color-dark-green;
      text-decoration: none;
    }
  }
}
