@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

// components
@import "../components";

@import "./site/index";
@import "./ecm_style";

//aos
@import "~aos/src/sass/aos";

// react-image-gallery
@import "~react-image-gallery/styles/css/image-gallery.css";

@media print {
  @import "./print";
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
  }
  div.file__attach__text {
    margin-left: 1rem;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  div.cms_item.mobile-ig-feed {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.btn {
  border-radius: 0;
  color: white;
}

.in_common_footer {
  iframe {
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
  }
}

.remove__button button {
  color: black;
}

.search-result.productList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
}

// a:hover {
//   color: #989898;
// }

// change the products colour font to black
.pageTile__big__wrapper .pageTile__big__middle__text {
  color: #212121;
}
// increase the Category left and right margin
@media (min-width: 1600px) {
  .container .pageTiles__big {
    margin-left: -100px;
    margin-right: -100px;
  }
}
@media (min-width: 2500px) {
  .container .pageTiles__big {
    margin-left: -300px;
    margin-right: -300px;
  }
}
.container .pageTiles__big {
  justify-content: center;
}
@media (min-width: 992px) {
  .pageTiles__big .pageTile__big {
    flex: 1 0 22%;
    max-width: 22%;
  }
}

.product__tile__name,
.product__tile__des {
  font-family: $family-header;
}
.pageTile__big__middle__text,
.pageTile__big__title {
  font-family: $family-header;
}

.product__page__tiles.slider {
  margin: 0 !important;
  @media screen and (min-width: 992px) {
    margin: 0 60px !important;
  }
  @media screen and (min-width: 1200px) {
    margin: 0 30px !important;
  }
  @media screen and (min-width: 1600px) {
    margin: 0 -100px !important;
  }
  @media screen and (min-width: 2500px) {
    margin: 0 -200px !important;
  }
}

.cms_item.rotatingReviewItems {
  cursor: grab;
}

.pageTile__fifth__button {
  font-weight: 600;
  padding: 10px 24px;
  font-size: 0.8rem;
  line-height: 1;
  letter-spacing: 1px;
}

dir.row.order__items__c {
}

// react-image-gallery
.photoSlide .image-gallery-right-nav,
.photoSlide .image-gallery-left-nav {
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.4);
}
.photoSlide .image-gallery-left-nav .image-gallery-svg,
.photoSlide .image-gallery-right-nav .image-gallery-svg {
  width: 32px;
  height: 51px;
  @media screen and (max-width: 576px) {
    width: 13px;
    height: 32px;
  }
}
.photoSlide .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  aspect-ratio: 1.7;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  aspect-ratio: 1.7;
}
.photoSlide .fslightbox-toolbar-button.fslightbox-flex-centered:first-child {
  display: none;
}
:not(:root):fullscreen::backdrop {
  height: 100%;
}
.fslightbox-open .cms_item.photoSlide {
  height: 100vh;
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container {
  top: 81px;
  @media screen and (max-width: 576px) {
    top: 44px;
  }
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container .fslightbox-source {
  transform: translateY(-57px);
}

.button_log_in{
  &:hover{
    color: #8d2b00;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
  }
}

.text-muted {
  color: black !important;
}

#contact.contentPanel a.button{
  background-color: #464934;
  color: white;
  &:hover{
    color: #daa520;
  }
}