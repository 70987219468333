@import '../lib/dev-mediaQueryStatus/scss';
@import '../lib/ui-loading/scss';

@import '../lib/youtube/scss';

.product__page__tiles button.slick-arrow.slick-prev {
  @media screen and (min-width: 2500px) {
    left: -70px;
  }
}
