.bannerPanel > * {
  display: none;

  &:nth-child(1) {
    display: block;
  }
}


.bannerPanel .slick-slider .slick-track {
  background-color: #414042;
}
.slick-next { 
  right: 8px; z-index: 1;
  @include media-breakpoint-up(md){
    right: 20px;
  }
}
.slick-prev { 
  left: 8px; z-index: 1;
  @include media-breakpoint-up(md){
    left: 20px
  }
 }