.searchBar {
  //margin-top: 70px;
  padding: 15px;
}

.searchBar__input {
  @include placeholder-color(#000);
  background-color: #ded4bf;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid #ded4bf;
  border-radius: 0.25rem;
  color: #464934;
  &::placeholder{
    color: #464934;
    font-weight: 600;
  }
}