.breadcrumb {
  text-align: left;
  padding:0;
  font-size: 0.75rem;
  &, li {
    list-style: none;
  }

  li {
    position: relative;
    margin-right: 1.5rem;
    display: inline-block;
    &:after {
      content: ">";
      position: absolute;
      right: -1rem;
    }
  }

  li:last-child() {
    &:after {
      display:none;
    }
  }
}