// overriding bootstrap variables in _boostrap-override.scss
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:wght@300;500;600&family=Roboto+Condensed:wght@300;700&display=swap');
@import url('https://use.typekit.net/lnh8jse.css');

$family-base: 'proxima-nova', sans-serif;
$family-header: 'Roboto Condensed', sans-serif;
$family-header-h3: 'banshee-std', sans-serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-normal: $weight-regular;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;

$color-orange: #fd7e14;

$color-dark-green: #769528;
$color-mid-grey: #414141;

// more colors
$color-gold: #faa222;
$color-dark-gray: #212121;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

$color-green: #1a2d13;
$color-lightgreen: #515e43;
$color-red: #8d2b00;
$color-light-red: #493b3f;
$color-light-grey: #ded4bf;
$color-yellow: #f9c700;

// site colors
$primary-color: $color-red;
$font-color: $color-light-red;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $primary-color;
$link-hover-color: $color-yellow;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  mobile: 44px,
  small-device: 56px,
  tablet: 56px,
  desktop: 56px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
